import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import BlogDetailsContent from '../components/BlogContent/BlogDetailsContent'
import { Link, graphql } from 'gatsby'

const BlogDetails = ({ data }) => {
  const articles = data.allStrapiNewblog;
  const article = data.strapiNewblog;
  return (
    <Layout>
      <Navbar />
      {/*<PageBanner
                pageTitle="Blog Details"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Blog Details"
            />*/}
      <BlogDetailsContent article={article} articles={articles} />
      <Footer />
    </Layout>
  )
}

export default BlogDetails;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    strapiNewblog(Slug: { eq: $slug }) {
      Author
      Date(formatString: "MMM DD, YYYY")
      Markdown
      Outspoke
      Published
      Slug
      Snippet
      Title
      Markdown
      id
      companyArticlesZone
      meta {
        id
        name
        value
      }
      MainImage {
        url
        localFile {
          publicURL
        }
      }
    }
    allStrapiNewblog {
      edges {
        node {
          Slug
          Author
          Date
          MainImage {
            url
            localFile {
              publicURL
            }
          }
          Markdown
          Outspoke
          Published
          Snippet
          Title
          id
        }
      }
    }
  }
`
